import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import Heading from './heading'
import HeroAnimation from './hero-animation'

const AnimatedHero = ({
  style,
  title,
  subtitle,
  description,
  animation,
  titleHeadingType,
  descriptionHeadingType,
}) => {
  return (
    <div
      css={[
        global`layout.container`,
        global`layout.grid`,
        tw`w-full h-full py-20 bg-transparent lg:(py-32 items-center)`,
        style,
      ]}
    >
      <div css={tw`col-span-4 md:col-span-8 lg:col-span-8 xl:col-span-9`}>
        {subtitle && (
          <Heading
            content={subtitle}
            headingType={descriptionHeadingType}
            style={tw`font-normal text-opacity-60`}
          />
        )}
        <Heading content={title} headingType={titleHeadingType} style={[tw`mt-12 font-medium`]} />
        {description && (
          <Heading
            content={description}
            headingType={descriptionHeadingType}
            style={tw`pt-12 font-normal`}
          />
        )}
      </div>
      <div
        css={tw`col-start-2 col-end-5 mt-8 md:(col-start-5 col-end-9) lg:(col-start-9 col-end-13 mt-0) xl:(col-start-11 col-end-16)`}
      >
        {animation && <HeroAnimation animationData={animation} />}
      </div>
    </div>
  )
}

AnimatedHero.defaultProps = {
  titleHeadingType: 'h2',
  descriptionHeadingType: 'h5',
}

AnimatedHero.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }).isRequired,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }).isRequired,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }),
  ]),
  animation: PropTypes.object,
  titleHeadingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  descriptionHeadingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

export default AnimatedHero
